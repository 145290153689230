import React from 'react';
import styled from 'styled-components';
import {LINKS, SHOP_INFO} from '../../Domain/Constants';
import imgLogoBlock from '../../images/logo-block.png';
import imgLogoV from '../../images/logo-v.png';
import {LogoFacebook} from '@styled-icons/ionicons-solid/LogoFacebook';
import {LogoInstagram} from '@styled-icons/ionicons-solid/LogoInstagram';
import {Pin} from '@styled-icons/ionicons-outline/Pin';
import {useOutlet} from 'reconnect.js';

function AboutPage(props) {
  const [dimension] = useOutlet('dimension');
  const {rwd} = dimension;

  return (
    <Wrapper>
      <div className="info">
        <img src={imgLogoBlock} alt="dotdot-logo" />
        <h2>
          小和好點
          <br />
          dot.dot. Bakery & Cafe
        </h2>
        <h3>肉桂捲專門店</h3>
        <div className="socials">
          <a
            className="social"
            href={LINKS.instagram}
            target={'_blank'}
            rel="noreferrer">
            <LogoInstagram color={'#000'} size={24} />
          </a>
          <a
            className="social"
            style={{marginLeft: 20}}
            href={LINKS.facebook}
            target={'_blank'}
            rel="noreferrer">
            <LogoFacebook color={'#000'} size={24} />
          </a>
        </div>
        <div className="meta">
          <Pin color={'#000'} size={16} style={{marginRight: 5}} />{' '}
          {SHOP_INFO.address}
        </div>
      </div>
      <h1 style={{textAlign: 'center', marginBottom: 50}}>關於「小和」...</h1>
      <div className="chapter">
        <div className="title">Chapter 1.</div>
        <div className="subtitle">
          大概是在都市生活過後才能夠真真正正的珍惜花蓮這片地土的純淨美好，一切是這麼的純真而自然，從樸實的人到作物，先是將叔公留下來的這塊地改造成家「小和農村」
          讓初來乍到花蓮的你，可以有一塊落腳而又接近山水的「好」住處，在這裡，你很接近大自然的原貌，附近甚至還有鄰居依然使用木柴生火洗澡煮飯，在溪邊洗衣！
        </div>
        <iframe
          src={`https://www.instagram.com/p/BwgnmEKnE0T/embed`}
          width={rwd === 'desktop' ? 492 : 300}
          height={rwd === 'desktop' ? 530 : 400}
          frameborder="0"
          scrolling="no"
          allowtransparency="true"
        />
        <small>小和農村：花蓮縣壽豐鄉平和村平和一街119號</small>
        <div>
          <span>小河農村：</span>
          <a
            style={{color: '#846e4f'}}
            href="https://www.facebook.com/ThePeacefulVillage/">
            https://www.facebook.com/ThePeacefulVillage/
          </a>
        </div>
      </div>
      <div className="chapter">
        <div className="title">Chapter 2.</div>
        <div className="subtitle">
          {
            '而後我們為了吃上一頓「好」的，開始鑽研如何就地取材供應一份「好」的早餐，因此有了「小和廚房」\n在每個週末開放給未能前來住宿而又想要吃上一頓屬於“農村”早午餐的你們，為了讓更多人了解花蓮之所以為花蓮，不但只是有山有水有善良的人，更有肥沃的土壤不該浪費，因此我們給了家父一項耕耘的功課，那功課就是「小和農場」，父親不旦開始下田供應我們蔬食，還養了一群雞鴨，過上了農務退休生活！'
          }
        </div>
        <iframe
          src={`https://www.instagram.com/p/CGFcqttHeyU/embed`}
          width={rwd === 'desktop' ? 492 : 300}
          height={rwd === 'desktop' ? 530 : 400}
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
        />
        <small>小和農村住宿早餐</small>
      </div>

      <div className="chapter">
        <div className="title">Chapter 3.</div>
        <div className="subtitle">
          {
            '「好」的生活品質需要好的三餐和令人開心的甜點，於是再誕生了「小和山谷」 位於花東縱谷風景區的路旁，\n我們分享自己的創意料理提供了“午餐”和“晚餐”，如此一來有了「好」住處「好」餐食，還少了什麼呢？'
          }
        </div>
        <iframe
          src={`https://www.instagram.com/p/CEgHpdRHi5W/embed`}
          width={rwd === 'desktop' ? 492 : 300}
          height={rwd === 'desktop' ? 530 : 400}
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
        />
        <small>小和山谷：花蓮縣壽豐鄉壽文路43號</small>
        <div className="subtitle">
          {
            '每當完成一項設定目標後，興高采烈的與他鄉朋友們分享著，這樣的生活方式多麽美好同時，遠在他方的朋友們總會因爲工作或生活的勞煩纏身不能親身經歷，這樣的可惜讓我們開始思考，該怎麼樣讓他們也能同我們所樂呢？'
          }
        </div>
        <div>
          <span>小河山谷：</span>
          <a
            style={{color: '#846e4f'}}
            href="https://www.facebook.com/PeacefulValley43/">
            https://www.facebook.com/PeacefulValley43/
          </a>
        </div>
        <div>
          <span>小河山谷 Ft. 平和飯店：</span>
          <a style={{color: '#846e4f'}} href="https://peacefulvalley.waca.ec/">
            https://peacefulvalley.waca.ec/
          </a>
        </div>
      </div>
      <div className="chapter">
        <div className="title">Chapter 4.</div>
        <div className="subtitle">
          {
            '回歸初心後，思考這一切的出發點和源頭，就因為這個「好」字，我們想要分享、希望傳達的就是花蓮的美和「好」，而若不能親身經歷，我們依然渴望能盡我們所能分享這份「好」，因此我們決定將原來的「小和廚房」\n改為 「小和好點」，不再只是供應早午餐，而是將我們這些年來的美「好」精華（甜點＆咖啡＆手工醬料）等產品，不僅只有來到店裡才能享用，而是透過網路平台，分享給他方的你！\n「好」東西與「好」的朋友 分享！'
          }
        </div>
        <iframe
          src={`https://www.instagram.com/p/CH2uDG4HA65/embed`}
          width={rwd === 'desktop' ? 492 : 300}
          height={rwd === 'desktop' ? 700 : 500}
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
        />
        <small>小和好點：花蓮縣壽豐鄉平和一街119號(小和農村旁)</small>
        <div className="subtitle">
          {
            '小和一直以來堅持這樣的初心在製作和經營著每個細節每個餐點，這樣的美好特別在這個年代裡更顯得可貴而不易，我們能做的，僅是把握這當中能抓住的所有「好」分享給你們！'
          }
        </div>
        <iframe
          src={`https://www.instagram.com/p/CMHDxgRnMTD/embed`}
          width={rwd === 'desktop' ? 492 : 300}
          height={rwd === 'desktop' ? 530 : 400}
          frameBorder="0"
          scrolling="no"
          allowTransparency="true"
        />
        <div className="subtitle">
          {
            '若是生活當中有太多的烏雲罩頂，來趟「小和農村」吧！\n\n「好」的生活，口說無憑。\n'
          }
        </div>
      </div>
      <div className="footer-block">
        <img src={imgLogoV} alt="dotdot-logo-v" />
        <div className="context">
          “dot.dot. Bakery & Cafe”, no longer just serve brunch, but the essence
          of our beauty (desserts & coffee & handmade sauces) and other products
          over the years, not only in the store to enjoy, but through the
          Internet, share with you!
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & > .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;

    & img {
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
    }

    & h2 {
      font-size: 20px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 28px;
      margin-bottom: 10px;
    }

    & h3 {
      margin-bottom: 50px;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;
    }

    & .socials {
      display: flex;
      border-top: 1px solid #846e4f;
      border-bottom: 1px solid #846e4f;
      padding: 25px 20px;

      & > .social {
      }
      & > .social:hover {
        opacity: 0.6;
      }
    }

    & .meta {
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;

      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 100px;
    }
  }
  & > .chapter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 135px;
    & > .title {
      font-family: STSongti-TC-Bold;
      font-size: 20px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 10px;
    }
    & > .subtitle {
      font-family: STSongti-TC-Bold;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;
      max-width: 820px;
      margin: 20px 0px;
    }
  }
  & > .footer-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    & > img {
      width: 156px;
      height: 80px;
      object-fit: cover;
      margin-bottom: 30px;
    }
    & > .context {
      font-family: NotoSerif;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      line-height: 25px;
      max-width: 600px;
    }
  }
`;

export default AboutPage;
